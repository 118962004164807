import videos from "constants/videos";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import MVTTruckImg from "../assets/images/MVT_Truck.png";
// import MailingAddressUpdateThumbnail from "../assets/images/mailing-address-update-thumbnail.png";

export default function LandingPage() {
  return (
    <>
      <Helmet>
        <title>Home</title>
      </Helmet>
      <div className="bg-gray-200 py-10">
        <div className="container mx-auto grid sm:grid-cols-2 md:grid-cols-3 gap-4">
        {/*<Link to="/documents/3" className="flex flex-col">
            <img src={MailingAddressUpdateThumbnail} alt="" className="flex-1 object-cover" />
            <div className="bg-white p-4">
              <p className="font-bold">Mailing Address Update</p>
            </div>
          </Link>
          <Link to="/documents/2" className="flex flex-col">
            <img src={MVTTruckImg} alt="" className="flex-1 object-cover" />
            <div className="bg-white p-4">
              <p className="font-bold">HR Announcement</p>
            </div>
          </Link> */}
          <Link to="/documents/1" className="flex flex-col">
            <img src={MVTTruckImg} alt="" className="flex-1 object-cover" />
            <div className="bg-white p-4">
              <p className="font-bold">Workflow Updates and Urgent Messages</p>
            </div>
          </Link>

          {videos.map((video) => (
            <Link key={video.id} to={`/videos/${video.id}`} className="flex flex-col">
              <img src={video.thumbnailUrl} alt="" className="flex-1 object-cover" />
              <div className="bg-white p-4">
                <p className="font-bold">{video.title}</p>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </>
  );
}
