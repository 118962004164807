import videos from "constants/videos";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link, useParams } from "react-router-dom";
import VenoBox from "venobox";
import styles from "./single-video.module.css";

export default function SingleVideo() {
  const { id } = useParams();

  const video = videos.find((video) => video.id === Number(id));

  useEffect(() => {
    new VenoBox({
      selector: ".my-video-links",
      maxWidth: "85%",
      onPostOpen() {
        document.querySelector(".vbox-container").style.overflowY = "hidden";
      },
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>{video.title}</title>
      </Helmet>
      <div className="bg-gray-200 py-8">
        <div className="max-w-4xl mx-auto">
          {/* Video */}
          <div className="mb-4">
            <div className="relative">
              <img src={video.thumbnailUrl} alt="" />
              <a
                data-autoplay="true"
                data-vbtype="video"
                href={video.videoUrl}
                className="my-video-links absolute top-0 bg-[rgba(17,24,39,0.5)] h-full w-full flex justify-center items-center"
              >
                <div className={styles.button} />
              </a>
            </div>
            <div className="bg-white p-4 font-bold flex text-sm">
              <a
                className="my-video-links bg-primary text-white rounded px-4 py-2 text-center"
                data-autoplay="true"
                data-vbtype="video"
                href={video.videoUrl}
              >
                Watch Now
              </a>
              {video.videoSubtitleUrl ? (
                <a
                  className="ml-4 my-video-links bg-red-700 text-white rounded px-4 py-2 text-center"
                  data-autoplay="true"
                  data-vbtype="video"
                  href={video.videoSubtitleUrl}
                >
                  Ver Con Subtitulos En Español
                </a>
              ) : null}

              <Link to="/" className="ml-4 bg-gray-700 text-white rounded px-4 py-2 text-center">
                View All Training Videos
              </Link>
            </div>
          </div>
          {/* Transcript */}
          {video.transcript ? (
            <div className="bg-white p-4 text-gray-800 text-lg">
              <img src={video.transcript} alt={`${video.title} Transcript`} />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
}
