import { Link, useLocation } from "react-router-dom";
import DriverWorkflowUpdates1 from "../assets/documents/DRVR_Workflow-Urgent_Updates-1.png";
import DriverWorkflowUpdates2 from "../assets/documents/DRVR_Workflow-Urgent_Updates-2.png";
import HRAnnouncement1 from "../assets/documents/HR Announcement Dec 2023 PDF-1.png";
import HRAnnouncement2 from "../assets/documents/HR Announcement Dec 2023 PDF-2.png";
import HRAnnouncement3 from "../assets/documents/HR Announcement Dec 2023 PDF-2.png";
import MailingAddressUpdate from "../assets/documents/mailing-address-update.png";

export default function DocumentsPage() {
  const location = useLocation();

  return (
    <>
      <div className="bg-gray-200 py-8">
        <div className="max-w-4xl mx-auto">
          <Link to="/" className="bg-gray-700 inline-block text-white rounded px-4 py-2 text-center mb-4">
            Back
          </Link>
          {location.pathname === "/documents/1" ? (
            <>
              <div className="bg-white p-4 text-gray-800 text-lg">
                <img src={DriverWorkflowUpdates1} alt="Driver Workflow Updates 1" />
                <img src={DriverWorkflowUpdates2} alt="Driver Workflow Updates 2" />
              </div>
            </>
          ) : location.pathname === "/documents/2" ? (
            <div className="bg-white p-4 text-gray-800 text-lg">
              <img src={HRAnnouncement1} alt="HR Announcements 1" />
              <img src={HRAnnouncement2} alt="HR Announcements 2" />
              <img src={HRAnnouncement3} alt="HR Announcements 3" />
            </div>
          ) : location.pathname === "/documents/3" ? (
            <div className="bg-white p-4 text-gray-800 text-lg">
              <img src={MailingAddressUpdate} alt="HR Announcements 1" />
            </div>
          ) : (
            <div className="bg-white p-4 text-gray-800 text-lg">
              <p className="font-semibold">Document not found.</p>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
