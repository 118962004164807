import LandingPage from "pages/landing";
import SingleVideoPage from "pages/videos/single-video";
import DocumentsPage from "pages/documents";
import { Route, Routes } from "react-router-dom";
import "./App.css";

function App() {
  return (
    <>
      <Routes>
        <Route index element={<LandingPage />} />
        <Route path="/videos/:id" element={<SingleVideoPage />} />
        <Route path="/documents/:id" element={<DocumentsPage />} />
      </Routes>
    </>
  );
}

export default App;
